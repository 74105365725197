:root {
  --bubbleIcon: 30px;
  --bubbleBackground: #F4F5F8;
  --header: #343541;
  --radius: 10px;
  --border-color: rgba(0, 0, 0, 0.2);
  --navbar-title-color: #3279E9;
  --navbar-page-color: ##242A31;
}
.textarea-placeholder::placeholder {
  color: white; /* Placeholder color */
}
main {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh
}

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
@keyframes slideIn {
  0% {
    transform: translate(-50%, -50%) scale(0.1)
  }
  100% {
    transform: translate(-50%, -50%) scale(1)
  }
}
@keyframes slideOut {
  0% {
    transform: translate(-50%, -50%) scale(1)
  }
  100% {
    transform: translate(-50%, -50%) scale(0)
  }
}

/* Bubble */
.bubble-container {
  max-width: 70%;
}

.bubble-icon {
  width: var(--bubbleIcon);
  height: var(--bubbleIcon);
  object-fit: contain;
}

.bubble-body {
  background-color: var(--bubbleBackground);
  position: relative;
  border-radius: 20px;
}

.chat {
  font-weight: 450;
}

/* Code */
.code-header{
  background: var(--header);
  color: 'white';
  padding-left: 20px;
  padding: 10px;
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
  font-size: 12px;
}

.code {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  color: white;
  margin-top: 0;
  border-bottom-left-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
  overflow: auto;
  overflow-y: hidden
}

/* Components */
.button-list {
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--border-color);
  margin-left: 10px;
  margin-right: 10px;
}

.button-border {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--border-color);
}

.button-full-border {
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--border-color);
}

.button {
  padding-top: 15px;
  padding-bottom: 15px;
}

.button:hover {
  background-color: var(--border-color);
  cursor: pointer;
}


/* Auth */
@media screen and (max-width: 990px) {
  .auth-image {
    display: none;
  }
}

.auth-title {
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  color: #323232;
}

.auth-subtitle {
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 65px;
  color: #888FA6;
}

.auth-navigation {
  margin-top: 20px;
  color: #323232;
}

link {
  color: #3279E9;
  text-decoration: underline;
}

/* Form */
.form-container {
  width: 100%;
  margin-right: 255px;
}

.form-input-container {
  margin-bottom: 20px;
}

.form-label {
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 12px;

  color: #323232;
}

.form-input {
  box-sizing: border-box;

  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 18px;

  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 6px;
}

.full-width-border {
  width: 100%;
  border-top-style: solid;
  border-width: 1px;
  border-color: var(--border-color);
}

/* Navbar */
@media screen and (max-width: 990px) {
  .navbar-pages-container {
    display: none;
  }
}

@media screen and (min-width: 990px) {
  .dropdown-options {
    display: none;
  }
}

.navbar-container {
  background: #FFFFFF;
  border-bottom: 1px solid rgb(226, 227, 230);
}

.navbar-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;

  color: var(--navbar-title-color);
}

.navbar-pages {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--navbar-page-color);
  text-decoration: none;
}

.navbar-selected {
  color: var(--navbar-title-color);
  border-bottom-style: solid;
  border-color: var(--navbar-title-color);
}

.navbar-profile {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
}

/* Home Page */
.page-title {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  color: var(--navbar-title-color);
}

.question {
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 16px;
  height: fit-content;
}

.question-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #242A31;
}

.question-timestamp {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #A5A2A2;
}

.question-answer {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #242A31;
}

.question-input {
  width: 869px;
}

/* Chat */

@media screen and (max-width: 990px) {
  .sidebar {
    display: none;
  }
}

.sidebar {
  flex: 1;
}

.list-container {
  flex: 4;
}

.list {
  flex: 1;
  overflow: scroll;
  overflow-x: hidden;
}


/*--------------------------------------------------------------
# Error 404
--------------------------------------------------------------*/
.error-404 {
  padding: 30px;
}

.error-404 h1 {
  font-size: 180px;
  font-weight: 700;
  color: #4154f1;
  margin-bottom: 0;
  line-height: 150px;
}

.error-404 h2 {
  font-size: 24px;
  font-weight: 700;
  color: #012970;
  margin-bottom: 30px;
}

.error-404 .btn {
  background: #51678f;
  color: #fff;
  padding: 8px 30px;
}

.error-404 .btn:hover {
  background: #3e4f6f;
}

@media (min-width: 992px) {
  .error-404 img {
    max-width: 50%;
  }
}


/*--------------------------------------------------------------
# Profie Page
--------------------------------------------------------------*/
.profile .profile-card img {
  max-width: 120px;
}

.profile .profile-card h2 {
  font-size: 24px;
  font-weight: 700;
  color: #2c384e;
  margin: 10px 0 0 0;
}

.profile .profile-card h3 {
  font-size: 18px;
}

.profile .profile-card .social-links a {
  font-size: 20px;
  display: inline-block;
  color: rgba(1, 41, 112, 0.5);
  line-height: 0;
  margin-right: 10px;
  transition: 0.3s;
}

.profile .profile-card .social-links a:hover {
  color: #012970;
}

.profile .profile-overview .row {
  margin-bottom: 20px;
  font-size: 15px;
}

.profile .profile-overview .card-title {
  color: #012970;
}

.profile .profile-overview .label {
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
}

.profile .profile-edit label {
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
}

.profile .profile-edit img {
  max-width: 120px;
}